<template>
  <div class="flex flex-1">
    <PopupEtreRappele></PopupEtreRappele>
    <router-view
      v-if="
        (!$route.params.id_address ||
          ($route.params.id_address && this.all_state_updated)) &&
        (!routes_contains_data_poi.includes($route.name) ||
          (routes_contains_data_poi.includes($route.name) &&
            !poi.is_loading)) &&
        ($route.name !== 'risque' ||
          ($route.name === 'risque' && !risque.is_loading)) &&
        ($route.name !== 'urbanisme' ||
          ($route.name === 'urbanisme' && !infos_urbanisme.is_loading)) &&
        ($route.name !== 'EtudeCommerciale' ||
          ($route.name === 'EtudeCommerciale' && !is_etude_commerciale_loading))
      "
    ></router-view>

    <div v-else class="flex flex-1 w-full items-center justify-center">
      <LoaderPromy classes="w-6" :color="'#10AAAF'"></LoaderPromy>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import batiment from '@/mixins/batiment'
import parcelle from '@/mixins/parcelle'
import PopupEtreRappele from './PopupEtreRappele.vue'

import Mapbox from '@/mixins/map/mapbox'

export default {
  components: {
    PopupEtreRappele,
  },
  mixins: [batiment, Mapbox, parcelle],
  data() {
    return {
      all_state_updated: false,
      routes_contains_data_poi: [
        'Commodite',
        'evolutionDesPrix',
        'reseaux',
        'pollution',
      ],
    }
  },
  created() {
    if (this.$route.params.id_address && !this.address) {
      this.getAddress(this.$route.params.id_address)
    } else if (this.$route.params.id_address) {
      this.all_state_updated = true
    }
  },
  computed: {
    ...mapGetters({
      address: 'address/address',
      is_data_loaded: 'address/is_data_loaded',
      parcelles_autour: 'parcelle/parcelles_autour',
      features_autour: 'parcelle/features_autour',
      cancel_save_selection_parcelles:
        'parcelle/cancel_save_selection_parcelles',
      is_parcelle_principale_changed: 'address/is_parcelle_principale_changed',
      id_time_out_popup_etre_rappele: 'address/id_time_out_popup_etre_rappele',
      is_address_changing: 'address/is_address_changing',
      poi: 'address/getPOI',
      risque: 'address/getRisque',
      infos_parcelles: 'address/infos_parcelles',
      current_parcelle: 'parcelle/current_parcelle',
      infos_urbanisme: 'address/infos_urbanisme',
      is_etude_commerciale_loading: 'address/is_etude_commerciale_loading',
      intendedAddress: 'auth/guestUserIntendedAddress',
      isLoggedIn: 'auth/isLoggedIn',
    }),
  },
  watch: {
    is_data_loaded(is_loaded) {
      if (is_loaded) {
        this.initStateParcelle()

        this.all_state_updated = true
        if (this.$route.meta.inside_address) {
          this.showPopupEtreRappele()
        }
      }
    },
    cancel_save_selection_parcelles(is_canceled) {
      if (is_canceled) {
        this.initStateParcelle()
        this.$store.commit('parcelle/CANCEL_SAVE_SELECTION_PARCELLES', false)
      }
    },
    $route: {
      handler(to, from) {
        if (!to.meta.inside_address) {
          this.clearTimeoutPopupEtreRappele()
        } else if (
          !this.id_time_out_popup_etre_rappele &&
          this.is_data_loaded
        ) {
          this.showPopupEtreRappele()
        }
        if (this.is_parcelle_principale_changed && to.meta.inside_address) {
          this.all_state_updated = false
          this.$store.commit('address/IS_PARCELLE_PRINCIPALE_CHANGED', false)
          if (this.isLoggedIn) {
            this.getAddress(this.$route.params.id_address)
          } else {
            this.intendedAddress.prevent_parcelle_redirection =
              from.name === 'parcelle'
            this.showAddressWithoutAuth(this.intendedAddress)
          }
        }
      },
      immediate: true,
    },

    is_address_changing(is_changing) {
      if (is_changing) {
        this.clearTimeoutPopupEtreRappele()
      } else {
        this.clearTimeoutPopupEtreRappele()
        this.showPopupEtreRappele(true)
      }
    },
  },
  methods: {
    ...mapActions({
      getAddress: 'address/showAddress',
      showAddressWithoutAuth: 'address/showAddressWithoutAuth',
    }),
    clearTimeoutPopupEtreRappele() {
      clearTimeout(this.id_time_out_popup_etre_rappele)
      this.$store.commit('address/SET_ID_TIME_OUT_POPUP_ETRE_RAPPELE', null)
    },
    showPopupEtreRappele(is_address_changed = false) {
      if (
        !this.address.is_already_alert ||
        (is_address_changed && this.address.is_already_alert)
      ) {
        this.$store.commit(
          'address/SET_ID_TIME_OUT_POPUP_ETRE_RAPPELE',
          setTimeout(() => {
            this.$store.commit('address/SET_SHOW_POPUP_ETRE_RAPPELE', true)
          }, 20000),
        )
      }
    },
    initStateParcelle() {
      if (!this.infos_parcelles.length) return
      let parcelles = this.infos_parcelles.map((item) => {
        item.parcelle.properties.ref_cadastrale = this.getRefParcelle(
          item.parcelle,
        )
        return item.parcelle
      })

      this.$store.commit(
        'parcelle/CURRENT_PARCELLE',
        this.getParcellePrincipalThroughCenterOfAllParcelles(parcelles),
      )
      this.$store.commit(
        'parcelle/PARCELLES_VOISINES',
        parcelles.filter(
          (parcelle) =>
            parcelle.properties.id !== this.current_parcelle.properties.id,
        ),
      )

      this.$store.commit(
        'parcelle/CURRENT_BATIMENTS',
        this.getAllBatimentsWithUniqueId(),
      )
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!to.meta.inside_address) {
      this.clearTimeoutPopupEtreRappele()
    }
    next()
  },
}
</script>
