var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-1"},[_c('PopupEtreRappele'),(
      (!_vm.$route.params.id_address ||
        (_vm.$route.params.id_address && this.all_state_updated)) &&
      (!_vm.routes_contains_data_poi.includes(_vm.$route.name) ||
        (_vm.routes_contains_data_poi.includes(_vm.$route.name) &&
          !_vm.poi.is_loading)) &&
      (_vm.$route.name !== 'risque' ||
        (_vm.$route.name === 'risque' && !_vm.risque.is_loading)) &&
      (_vm.$route.name !== 'urbanisme' ||
        (_vm.$route.name === 'urbanisme' && !_vm.infos_urbanisme.is_loading)) &&
      (_vm.$route.name !== 'EtudeCommerciale' ||
        (_vm.$route.name === 'EtudeCommerciale' && !_vm.is_etude_commerciale_loading))
    )?_c('router-view'):_c('div',{staticClass:"flex flex-1 w-full items-center justify-center"},[_c('LoaderPromy',{attrs:{"classes":"w-6","color":'#10AAAF'}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }